import type {
  GetDrawSettingsPathParams,
  GetDrawStatusRequestParams,
  GetSegmentationListCachePathParams,
  PostGetLosersRequestBody,
  PostGetSignupsRequestBody,
  PostResetLosersRequestBody,
  PostStartDrawRequestBody,
  PostStopDrawRequestBody,
  PutDrawSettingsPathParams,
  PutDrawSettingsRequestBody,
  RefreshSegmentationListCachePathParams,
} from '@sneakersnstuff/sns-api-client/admin'
import type { BCInstance } from '@sneakersnstuff/sns-api-client/app/types'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useAdminApi } from '~/services/adminApi'

export const useStartDrawMutation = () => {
  const { draw } = useAdminApi()
  return useMutation({
    mutationFn: async (data: PostStartDrawRequestBody) => {
      const response = await draw.startDraw(data)
      return response
    },
    onSuccess: (response) => {
      return response
    },
  })
}

export const useStopDrawMutation = () => {
  const { draw } = useAdminApi()
  return useMutation({
    mutationFn: async (data: PostStopDrawRequestBody) => {
      const response = await draw.stopDraw(data)
      return response
    },
    onSuccess: (response) => {
      return response
    },
  })
}

export const useGetDrawStatusQuery = (sku: string, instance: BCInstance | null) => {
  const { draw } = useAdminApi()

  return useQuery({
    enabled: !!instance && !!sku,
    queryKey: ['draw', 'status', instance, sku],
    queryFn: async () => {
      if (!instance) {
        return null
      }
      const response = await draw.getDrawStatus({ sku, instance })
      return response
    },
  })
}

export const useGetDrawSettingsQuery = (sku: string, instance: BCInstance | null) => {
  const { draw } = useAdminApi()
  return useQuery({
    enabled: !!instance && !!sku,
    queryKey: ['draw', 'settings', instance, sku],
    queryFn: async () => {
      if (!sku || !instance) {
        return null
      }
      const response = await draw.getDrawSettings({ sku, instance })
      return response
    },
  })
}

export const usePutDrawSettingsMutation = (sku: string, instance: BCInstance | null) => {
  const queryClient = useQueryClient()
  const { draw } = useAdminApi()
  return useMutation({
    mutationFn: async (data: PutDrawSettingsRequestBody) => {
      if (!instance) {
        return null
      }
      const response = await draw.putDrawSettings({ sku, instance }, data)
      return response
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['draw', 'settings', instance, sku], data)
      queryClient.invalidateQueries({ queryKey: ['draw', 'status', instance, sku] })
    },
  })
}

export const useRefreshSegmentationMutation = ({ sku }: { sku: string }) => {
  const { draw } = useAdminApi()
  return useMutation({
    mutationKey: ['draw', 'segmentation', 'refresh', sku],
    mutationFn: async (params: RefreshSegmentationListCachePathParams) => {
      const response = await draw.refreshSegmentationCache(params)
      return response
    },
  })
}

export const useGetDrawHistoryQuery = (sku: string, instance: BCInstance | null) => {
  const { draw } = useAdminApi()
  return useQuery({
    enabled: !!instance && !!sku,
    queryKey: ['draw', 'history', instance, sku],
    queryFn: async () => {
      if (!sku || !instance) {
        return null
      }
      const response = await draw.getDrawHistory({ sku, instance })
      return response
    },
  })
}

export const useGetSegmentationCacheMutation = ({ sku }: { sku: string }) => {
  const { draw } = useAdminApi()
  return useMutation({
    mutationKey: ['draw', 'segmentation', sku],
    mutationFn: async (params: GetSegmentationListCachePathParams) => {
      const response = await draw.getSegmentationCacheStatus(params)
      return response
    },
  })
}

export const useResetLosersMutation = () => {
  const queryClient = useQueryClient()
  const { draw } = useAdminApi()
  return useMutation({
    mutationFn: async (params: PostResetLosersRequestBody) => {
      const response = await draw.resetLosers(params)
      await queryClient.invalidateQueries({ queryKey: ['draw', 'status', params.instance, params.sku] })
      return response
    },
  })
}

export const useGetLosersMutation = () => {
  const { draw } = useAdminApi()
  return useMutation({
    mutationFn: async (params: PostGetLosersRequestBody) => {
      if (!params.sku || !params.instance) {
        return null
      }
      const response = await draw.getLosers(params)
      return response
    },
  })
}

export const useGetSignupsMutation = () => {
  const { draw } = useAdminApi()
  return useMutation({
    mutationFn: async (params: PostGetSignupsRequestBody) => {
      if (!params.sku || !params.instance) {
        return null
      }
      const response = await draw.getSignups(params)
      return response
    },
  })
}
