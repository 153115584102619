import Link from 'next/link'

import UserNavigation from '~/components/navigation/userNavigation'

import SNSIcon from '~/components/icons/SNSIcon'

import MainNavigation from '~/components/navigation/mainNavigation'

import Providers from '~/components/layouts/providers'
import { Toaster } from '~/components/shadcn/toaster'
import { env } from '~/env'
import Search from '~/pages/raffles/search'
import { Environment } from '../navigation/components/environment'
import { EnvironmentPicker } from '../navigation/components/environmentPicker'
import RaffleDrawState from '../raffles/drawState/raffleDrawState'

export default function MainLayout({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <Providers>
      <div className='container mx-auto space-y-2 h-screen'>
        <header className='flex flex-row py-2 relative z-20'>
          <div className='grow flex space-x-2 justify-start'>
            <Link href='/raffles/overview'>
              <SNSIcon />
            </Link>
            <MainNavigation />
          </div>

          <Environment />

          {env.NEXT_PUBLIC_STAGE === 'local' ? (
            <div className='shrink flex mx-2'>
              <EnvironmentPicker />
            </div>
          ) : (
            <></>
          )}

          <div className='shrink flex space-x-2'>
            <Search />
            <UserNavigation />
          </div>
        </header>

        {children}

        <RaffleDrawState />
        <Toaster />
      </div>
    </Providers>
  )
}
