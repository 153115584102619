import { env } from '~/env'
import type { WebAdminEnvironment } from '~/lib/types'

export const LOCALSTORAGE_PREFIX = `${env.NEXT_PUBLIC_STAGE}-raffle-admin-`
export const environmentKey = `${LOCALSTORAGE_PREFIX}environment`

export const getEnvironment = (): WebAdminEnvironment | null => {
  return getLocalStorageItem(environmentKey) as WebAdminEnvironment
}

export const setEnvironment = (env: WebAdminEnvironment) => {
  return setLocalStorageItem(environmentKey, env)
}

export const getLocalStorageItem = (key: string) => {
  if (typeof localStorage === 'undefined') {
    return null // nextjs tries to load this even with "use client"
  }
  return localStorage.getItem(`${LOCALSTORAGE_PREFIX}${key}`)
}

export const setLocalStorageItem = (key: string, item: string) => {
  return localStorage.setItem(`${LOCALSTORAGE_PREFIX}${key}`, item)
}
