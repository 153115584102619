import { MenuItem } from '~/components/navigation/components/menuItem'

import type { MenuItemData } from '~/components/navigation/mainNavigation'

import { NavigationMenu, NavigationMenuList } from '~/components/shadcn/navigation-menu'

interface RootNavigationProps {
  mainMenu: MenuItemData[]
}

export function RootNavigation(props: RootNavigationProps) {
  const mainMenu = props.mainMenu

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {mainMenu.map((menuItem: MenuItemData) => (
          <MenuItem key={`section-${menuItem.title}`} menuItem={menuItem} />
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
