import type { BCInstance } from '@sneakersnstuff/sns-api-client/app/types'

import type { productCustomField_Base } from 'bigcommerce-api-client/lib/model/generated/catalog.v3'
import { type ClassValue, clsx } from 'clsx'

import { useRouter } from 'next/router'
import { useCallback, useMemo, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { env } from '~/env'

export const cn = (...inputs: ClassValue[]): string => {
  return twMerge(clsx(inputs))
}

export const usePathParam = (key: string): string => {
  const router = useRouter()
  const param = router.query?.[key]?.toString?.() ?? ''

  return param
}

export const useInstance = (): BCInstance | null => {
  const instance = usePathParam('instance')
  const router = useRouter()
  if (!router.isReady) {
    // router.query.instance is not avaliable in pre-render:
    // https://nextjs.org/docs/pages/api-reference/functions/use-router#router-object
    // "It will be an empty object during prerendering if the page doesn't use Server-side Rendering."
    return null
  }

  switch (instance.toUpperCase()) {
    case 'EU':
    case 'US':
    case 'UK':
      return instance.toUpperCase() as BCInstance
  }

  throw new Error('No instance in router')
}

export const getCustomField = (
  customFields: productCustomField_Base[] | undefined,
  fieldName: string,
): productCustomField_Base | null => {
  const customField = customFields?.find((field) => field.name === fieldName)

  if (!customField) {
    return null
  }

  return customField
}

export const isUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

export const useDebouncedCallback = <T extends (...args: unknown[]) => void>(
  callback: T,
  delay: number,
): [() => void, () => void] => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const cancel = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  const debouncedFn = useCallback(
    (...args: Parameters<T>) => {
      cancel()
      timeoutRef.current = setTimeout(() => callback(...args), delay)
    },
    [callback, delay, cancel],
  )

  return [debouncedFn as () => void, cancel]
}

export const getBigcommerceStoreUrlByInstance = (instance: BCInstance | null) => {
  switch (instance) {
    case 'EU':
      return `https://store-${env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_EU}.mybigcommerce.com`
    case 'UK':
      return `https://store-${env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_UK}.mybigcommerce.com`
    case 'US':
      return `https://store-${env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_US}.mybigcommerce.com`
    default:
      throw new Error(`Invalid instance: ${instance}`)
  }
}
