import { useEffect, useState } from 'react'

import type { DrawHistory } from '@sneakersnstuff/sns-api-client/admin'

import { Spinner } from '~/components/loading/spinner'

import { Card, CardContent } from '~/components/shadcn/card'
import { Progress } from '~/components/shadcn/progress'
import { useRaffleQuery } from '~/hooks'

import RaffleImage from '../ui/raffleImage'

interface Props {
  drawHistory: DrawHistory
  drawHistoryOpenId: number
  setOpen: (open: number) => void
}

export const RaffleDrawProgressTrigger = ({ drawHistory, drawHistoryOpenId, setOpen }: Props): React.JSX.Element => {
  const { data: raffle } = useRaffleQuery(drawHistory.sku, drawHistory.instance)
  const isOpen = drawHistoryOpenId === drawHistory.id

  const [animationState, setAnimationState] = useState('duration-500 animate-in slide-in-from-bottom')
  const [lastOpenState, setLastOpenState] = useState(isOpen)

  const winners = drawHistory.progress.stockLevels.reduce((accumulator, stockLevel) => {
    return accumulator + stockLevel.winners
  }, 0)
  const stock = drawHistory.progress.stockLevels.reduce((accumulator, stockLevel) => {
    return accumulator + stockLevel.stock
  }, 0)
  const progress = Math.floor(winners / stock) || 0

  useEffect(() => {
    if (isOpen !== lastOpenState) {
      if (isOpen) {
        setAnimationState('duration-500 animate-out fade-out opacity-0')
      } else {
        setAnimationState('duration-500 animate-in fade-in')
      }
      setLastOpenState(isOpen)
    }
  }, [isOpen, lastOpenState])

  const clickPill = () => {
    if (isOpen) {
      setOpen(-1)
    } else {
      setOpen(drawHistory.id)
    }
  }

  return (
    <Card
      className={`${animationState} duration-500 mb-2 w-96 relative h-[65px] overflow-hidden cursor-pointer border-zinc-600 bg-zinc-700`}
      onClick={clickPill}
    >
      {raffle ? (
        <CardContent className='p-0'>
          <RaffleImage className='absolute inset-x-0 top-0' width={65} height={65} raffle={raffle} />
          <div className='ml-16 p-3 flex justify-between items-center'>
            <div className='grow mr-4'>
              {raffle?.product.sku} | {raffle?.instance}
            </div>
            <div className='w-12 text-center'>
              {progress}%
              <Progress className='w-full' value={progress} />
            </div>
          </div>
        </CardContent>
      ) : (
        <CardContent className='p-0 flex justify-center items-center content-around w-full h-full'>
          <Spinner visible size={25} />
        </CardContent>
      )}
    </Card>
  )
}
