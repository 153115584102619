import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type React from 'react'
import { useState } from 'react'

import '~/styles/globals.css'

import Head from 'next/head'
// import { env } from '~/env'
import { ApiProvider } from '~/services/adminApi'
import { PreviousPathProvider } from '~/services/previousPath'
import { ThemeProvider } from '~/services/themeProvider'
import { WebsocketProvider } from '~/services/websocketProvider'

export default function Providers({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
      }),
  )

  return (
    <ThemeProvider attribute='class' defaultTheme='system' enableSystem disableTransitionOnChange>
      <Head>
        <title>Raffle Admin</title>
      </Head>
      <ApiProvider>
        <QueryClientProvider client={queryClient}>
          <WebsocketProvider>
            <PreviousPathProvider>{children}</PreviousPathProvider>
          </WebsocketProvider>
        </QueryClientProvider>
      </ApiProvider>
    </ThemeProvider>
  )
}
