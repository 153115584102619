import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  server: {
    AZURE_AD_CLIENT_ID: z.string().min(1),
    AZURE_AD_CLIENT_SECRET: z.string().min(1),
    AZURE_AD_TENANT_ID: z.string().min(1),
    NEXTAUTH_URL: z.string().min(1),
    NEXTAUTH_SECRET: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_STAGE: z.string().min(1),
    NEXT_PUBLIC_ADMIN_API_URL: z.string().min(1),
    NEXT_PUBLIC_ADMIN_API_URL_DEV: z.string().min(1),
    NEXT_PUBLIC_ADMIN_WEBSOCKET_API_URL: z.string().min(1),
    NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_EU: z.string().min(1),
    NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_US: z.string().min(1),
    NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_UK: z.string().min(1),
    NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_EU: z.string().min(1),
    NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_US: z.string().min(1),
    NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_UK: z.string().min(1),
  },
  // If you're using Next.js < 13.4.4, you'll need to specify the runtimeEnv manually
  // runtimeEnv: {
  //   DATABASE_URL: process.env.DATABASE_URL,
  //   OPEN_AI_API_KEY: process.env.OPEN_AI_API_KEY,
  //   NEXT_PUBLIC_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_PUBLISHABLE_KEY,
  // },
  // For Next.js >= 13.4.4, you only need to destructure client variables:
  experimental__runtimeEnv: {
    NEXT_PUBLIC_STAGE: process.env.NEXT_PUBLIC_STAGE,
    NEXT_PUBLIC_ADMIN_API_URL: process.env.NEXT_PUBLIC_ADMIN_API_URL,
    NEXT_PUBLIC_ADMIN_API_URL_DEV: process.env.NEXT_PUBLIC_ADMIN_API_URL_DEV,
    NEXT_PUBLIC_ADMIN_WEBSOCKET_API_URL: process.env.NEXT_PUBLIC_ADMIN_WEBSOCKET_API_URL,
    NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_EU: process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_EU,
    NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_US: process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_US,
    NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_UK: process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH_UK,
    NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_EU: process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_EU,
    NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_US: process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_US,
    NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_UK: process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_URL_UK,
  },
})
