import type {
  DeleteSegmentationsPathParams,
  PostSegmentationsRequestBody,
  SegmentationItem,
} from '@sneakersnstuff/sns-api-client/admin'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useAdminApi } from '~/services/adminApi'

export const useGetSegmentationsQuery = () => {
  const { segmentation } = useAdminApi()
  return useQuery({
    queryKey: ['segmentations'],
    queryFn: async (): Promise<SegmentationItem[]> => {
      const response: SegmentationItem[] = (await segmentation.get()) as SegmentationItem[]
      return response
    },
  })
}

export const usePostSegmentationsMutation = () => {
  const { segmentation } = useAdminApi()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ data }: { data: PostSegmentationsRequestBody }) => {
      const response = await segmentation.create(data)
      return response
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: ['segmentations'] })
      return response
    },
  })
}

export const useDeleteSegmentationsMutation = () => {
  const { segmentation } = useAdminApi()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ pathParams }: { pathParams: DeleteSegmentationsPathParams }) => {
      const response = await segmentation.delete(pathParams)
      return response
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: ['segmentations'] })
      return response
    },
  })
}
