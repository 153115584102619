/**
 * Save last visited location both in a provider and in local storage,
 * so we can return to that page after login.
 */

import { useRouter } from 'next/router'
import type React from 'react'
import { createContext, useContext, useEffect, useRef } from 'react'
import { env } from '~/env'
import { setLocalStorageItem } from './localstorage'

const PreviousPathContext = createContext<React.MutableRefObject<string | null>>({ current: null })

export const PreviousPathProvider = ({ children }: { children: React.ReactNode }) => {
  const { asPath } = useRouter()

  const asPathRef = useRef<string | null>(null)

  useEffect(() => {
    setLocalStorageItem('previousURL', asPath)
    asPathRef.current = asPath
  }, [asPath])

  return <PreviousPathContext.Provider value={asPathRef}>{children}</PreviousPathContext.Provider>
}

const usePreviousURL = () => useContext(PreviousPathContext)

export default usePreviousURL
