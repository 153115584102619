import { Search } from 'lucide-react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import type * as React from 'react'
import { Button } from '~/components/shadcn/button'
import { Card } from '~/components/shadcn/card'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/shadcn/dropdown-menu'
import { Input } from '~/components/shadcn/input'
import { usePathParam } from '~/lib/utils'

export const RafflesSearch = (): React.JSX.Element => {
  const router = useRouter()
  let instance = usePathParam('instance')
  const [value, setValue] = useState('')

  const [open, setOpen] = useState(false)

  const dropdownContainer = useRef(null)

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setOpen(true)
  }

  const goToSku = () => {
    if (!instance) {
      instance = 'EU'
    }
    const pathname = `/raffle/${instance}/${value.trim()}/dashboard`
    router.push({ pathname }, undefined, {})
    setOpen(false)
    setValue('')
  }

  const search = () => {
    const pathname = `/raffles/overview?search=${value.trim()}`
    router.push(pathname)
    setOpen(false)
    setValue('')
  }

  return (
    <div>
      <Head>
        <title>Raffles Search</title>
      </Head>

      <form onSubmit={onSubmit} className='inline-block relative'>
        <Search className='absolute left-2.5 top-3 h-4 w-4 text-muted-foreground' />
        <Input
          type='search'
          placeholder='Search'
          onChange={(e) => {
            setValue(e.target.value)
          }}
          value={value}
          className='pl-8 w-full'
        />
        <Card ref={dropdownContainer} className={`absolute w-full mt-1 p-2 ${open ? '' : 'hidden'}`} />
        <DropdownMenu open={open} modal={false}>
          <DropdownMenuContent className='w-56'>
            <DropdownMenuPortal container={dropdownContainer.current}>
              <DropdownMenuRadioGroup>
                <DropdownMenuRadioItem value='sku' onSelect={goToSku}>
                  Go to sku &quot;<strong>{value}</strong>&quot;
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem value='filter' onSelect={search}>
                  Search for sku &quot;<strong>{value}</strong>&quot;
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuPortal>
          </DropdownMenuContent>
        </DropdownMenu>
      </form>
    </div>
  )
}

export default RafflesSearch
