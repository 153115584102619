import { Oval, type OvalProps } from 'react-loader-spinner'

interface SpinnerProps extends OvalProps {
  visible: boolean
  color?: string
  secondaryColor?: string
  size?: number
}

export const Spinner = (props: SpinnerProps) => (
  <Oval
    height={props.size ? props.size : 24}
    width={props.size ? props.size : 24}
    ariaLabel='oval-loading'
    {...props}
  />
)
