import type {
  WebsocketChannel,
  WebsocketData,
  WebsocketDrawState,
  WebsocketGetDrawState,
} from '@sneakersnstuff/sns-api-client/admin'
import { websocketDrawStateSchema } from '@sneakersnstuff/sns-api-client/admin/generatedSchemas/types/websocket'

import type React from 'react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { env } from '~/env'

interface WebsocketContext {
  messages: WebsocketData[]
  sendMessage: (data: string | ArrayBufferLike | Blob | ArrayBufferView) => void
  clearMessages: (channel: WebsocketChannel) => void
}

const WebSocketContext = createContext<WebsocketContext>({
  messages: [],
  sendMessage: () => {},
  clearMessages: () => {},
})

export const useWebsocket = () => {
  const contextState = useContext(WebSocketContext)
  return contextState
}

export const WebsocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { sendMessage, lastMessage } = useWebSocket(env.NEXT_PUBLIC_ADMIN_WEBSOCKET_API_URL)

  const [messages, setMessages] = useState<WebsocketData[]>([])

  useEffect(() => {
    // Get draw state on connect
    const msg: Omit<WebsocketGetDrawState, 'timestamp'> = {
      channel: 'getDrawState',
    }
    sendMessage(JSON.stringify(msg))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      const message: WebsocketData = JSON.parse(lastMessage.data)

      // Parse so we get proper Date objects instead of strings, and so on
      switch (message.channel) {
        case 'drawState': {
          const drawStateMessage: WebsocketDrawState = websocketDrawStateSchema.parse(message) as WebsocketDrawState
          setMessages((prev) => prev.concat(drawStateMessage))
          break
        }

        default:
          setMessages((prev) => prev.concat(message))
          break
      }
    }
  }, [lastMessage])

  const clearMessages = (channel: WebsocketChannel) => {
    const newMessages = messages.filter((message) => message.channel !== channel)
    setMessages(newMessages)
  }

  return (
    <WebSocketContext.Provider value={{ messages, sendMessage, clearMessages }}>{children}</WebSocketContext.Provider>
  )
}
