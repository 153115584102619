import type { PutMaintenanceRequestBody } from '@sneakersnstuff/sns-api-client/admin'

import { useAdminApi } from '~/services/adminApi'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetMaintenanceQuery = () => {
  const { maintenance } = useAdminApi()
  return useQuery({
    queryKey: ['maintenance'],
    queryFn: async () => {
      const response = await maintenance.get()
      return response
    },
  })
}

export const usePutMaintenanceMutation = () => {
  const { maintenance } = useAdminApi()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ data }: { data: PutMaintenanceRequestBody }) => {
      const response = await maintenance.update(data)
      return response
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: ['maintenance'] })
      return response
    },
  })
}
