import { useEffect, useState } from 'react'

import type { DrawHistory } from '@sneakersnstuff/sns-api-client/admin'
import { useWebsocket } from '~/services/websocketProvider'
import { RaffleDrawProgress } from './raffleDrawProgress'
import { RaffleDrawProgressTrigger } from './raffleDrawProgressTrigger'

/**
 * Displays a list of currently drawing raffles in the bottom of the screen
 */
export default function RaffleDrawState(): React.JSX.Element {
  const { messages } = useWebsocket()
  const [drawState, setState] = useState<DrawHistory[]>([])
  const [drawHistoryOpenId, setOpen] = useState<number>(-1)

  useEffect(() => {
    for (const message of messages) {
      if (message && message.channel === 'drawState') {
        setState(message.data)
      }
    }
  }, [messages])

  useEffect(() => {
    if (drawState.length > 0 && drawHistoryOpenId === -1) {
      const firstItem = drawState[0]
      if (firstItem) {
        setOpen(firstItem.id)
      }
    }
  }, [drawState])

  const isOpen = drawHistoryOpenId !== -1
  const openClass = isOpen ? 'h-[120px] bg-black shadow-[0_0_50px_25px_rgba(0,0,0,1)]' : ''

  return (
    <>
      {isOpen ? <div className='h-44'>&nbsp;</div> : <div className='h-1'>&nbsp;</div>}

      <footer className={'fixed w-full bottom-0 left-0'}>
        <div className='p-4'>
          <div className='flex gap-2'>
            {/* reverse so when we open the first we don't get a gap when we more than one */}
            {drawState.reverse().map((item) => (
              <RaffleDrawProgressTrigger
                key={`trigger-${item.instance}-${item.executionId}`}
                drawHistory={item}
                drawHistoryOpenId={drawHistoryOpenId}
                setOpen={setOpen}
              />
            ))}
          </div>
          <div className={`${openClass}`}>
            {drawState.reverse().map((item) => (
              <RaffleDrawProgress
                key={`item-${item.instance}-${item.executionId}`}
                drawHistory={item}
                drawHistoryOpenId={drawHistoryOpenId}
                setOpen={setOpen}
              />
            ))}
          </div>
        </div>
      </footer>
    </>
  )
}
