'use client'

import { Construction, FlaskConical, Globe2, MessageSquare, Plug, UserRoundX, UsersRound } from 'lucide-react'

import { RootNavigation } from '~/components/navigation/components/rootNavigation'
import { env } from '~/env'

export interface MenuItemData {
  title: string
  description?: string
  icon?: React.ReactElement
  href?: string
  children?: MenuItemData[]
}

export default function MainNavigation(): React.JSX.Element {
  const mainMenu: MenuItemData[] = [
    {
      title: 'Raffles',
      href: '/raffles/overview',
    },
    {
      title: 'App',
      icon: <Globe2 className='mr-2 h-4 w-4' />,
      children: [
        {
          title: 'Global Message',
          icon: <MessageSquare className='mr-2 h-4 w-4' />,
          href: '/settings/globalMessage',
        },
        {
          title: 'Maintenance',
          icon: <Construction className='mr-2 h-4 w-4' />,
          href: '/settings/maintenance',
        },
      ],
    },
    {
      title: 'Settings',
      icon: <Globe2 className='mr-2 h-4 w-4' />,
      children: [
        {
          title: 'Segmentations',
          icon: <UsersRound className='mr-2 h-4 w-4' />,
          href: '/segmentations',
        },
        {
          title: 'Country Lists',
          icon: <Globe2 className='mr-2 h-4 w-4' />,
          href: '/countries',
        },
        {
          title: 'Delete users',
          icon: <UserRoundX className='mr-2 h-4 w-4' />,
          href: '/pendingDelete',
        },
      ],
    },
  ]

  if (env.NEXT_PUBLIC_STAGE !== 'prod') {
    const settings = mainMenu.find((item) => item.title === 'Settings')
    if (settings) {
      settings.children?.push({
        title: 'Development',
        icon: <FlaskConical className='mr-2 h-4 w-4' />,
        href: '/development',
      })
    }
  }

  return <RootNavigation mainMenu={mainMenu} />
}
