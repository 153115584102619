import type { MenuItemData } from '~/components/navigation/mainNavigation'

import { NavigationItem } from '~/components/navigation/components/navigationItem'

import { useRouter } from 'next/router'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/shadcn/dropdown-menu'
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '~/components/shadcn/navigation-menu'

interface MenuItemProps {
  menuItem: MenuItemData
}

export function MenuItem(props: MenuItemProps) {
  const menuItem = props.menuItem
  const router = useRouter()

  if (menuItem.children) {
    return (
      <li className=''>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <NavigationItem className={navigationMenuTriggerStyle()} title={menuItem.title} />
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-56'>
            <DropdownMenuLabel>{menuItem.title}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              {menuItem.children?.map((child: MenuItemData) => (
                <DropdownMenuItem
                  key={child.title}
                  onClick={() => {
                    router.push(child.href || '')
                  }}
                >
                  {child.icon} {child.title}
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </li>
    )
  }
  return (
    <NavigationMenuItem key={menuItem.title}>
      <NavigationItem href={menuItem.href || ''} className={navigationMenuTriggerStyle()} title={menuItem.title} />

      {/* // NavigationMenuLink causes a full page reload on navigation */}
      {/* <NavigationMenuLink href={ menuItem.href || '' } className={navigationMenuTriggerStyle()}>
          { menuItem.icon }
          { menuItem.title }
        </NavigationMenuLink> */}
    </NavigationMenuItem>
  )
}
