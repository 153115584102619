import type { NextPage } from 'next'
import type { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import NextNProgress from 'nextjs-progressbar'
import React, { type ReactElement, type ReactNode, useEffect } from 'react'

import '~/styles/globals.css'

import { Inter as FontSans } from 'next/font/google'

import type { AppProps } from 'next/app'
import MainLayout from '~/components/layouts/main'

export const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-sans',
})

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout<P> = AppProps<P> & {
  Component: NextPageWithLayout<P>
}

export default function App({ Component, pageProps }: AppPropsWithLayout<{ session: Session }>) {
  useEffect(() => {
    if (document) {
      // FontSans uses a className to add the font, but we can't load it
      // in _document.tsx since it's only rendered server-side.
      document.querySelector('body')?.classList.add(fontSans.variable)
    }
  }, [])

  const defaultLayout = (page: ReactElement) => {
    return (
      <MainLayout>
        <NextNProgress color='hsl(var(--primary))' height={2} />
        {page}
      </MainLayout>
    )
  }

  const getLayout = Component.getLayout ?? defaultLayout

  return <SessionProvider session={pageProps.session}>{getLayout(<Component {...pageProps} />)}</SessionProvider>
}
