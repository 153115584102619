'use client'

import type { product_Full } from 'bigcommerce-api-client/lib/model/generated/catalog.v3'
import Image from 'next/image'

import type { Raffle } from '~/hooks'

interface RaffleImageProps {
  raffle: Raffle
  className?: string
  width: number
  height: number
}

export default function RaffleImage({ raffle, className, width, height }: RaffleImageProps) {
  const product: product_Full = raffle.product
  let imageUrl = product.images?.find((image) => image.is_thumbnail)?.url_thumbnail
  const sku = raffle.product.sku || ''

  if (!imageUrl) {
    imageUrl = '/images/rafflePlaceholder.png'

    return (
      <div className={`${className} relative w-[${width}px] h-[${height}px] p-0 m-0`}>
        <div className={`absolute top-0 left-0 w-full h-full text-slate-700 grid content-center text-center`}>
          <p className='text-black text-lg text-center'>Missing Product Image</p>
        </div>
        <Image className={className} width={width} height={height} src={imageUrl} alt={raffle.product.name || sku} />
      </div>
    )
  }

  return <Image className={className} width={width} height={height} src={imageUrl} alt={raffle.product.name || sku} />
}
