import * as React from 'react'

import { env } from '~/env'

export const Environment = () => {
  const styling = 'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold '

  if (env.NEXT_PUBLIC_STAGE === 'local') {
    return <div className={`${styling} bg-blue-600`}>{env.NEXT_PUBLIC_STAGE}</div>
  }

  if (env.NEXT_PUBLIC_STAGE === 'dev') {
    return <div className={`${styling} bg-green-600`}>{env.NEXT_PUBLIC_STAGE}</div>
  }

  return null
}
