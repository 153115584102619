import type React from 'react'
import { type ReactElement, forwardRef } from 'react'

import Link from 'next/link'

import { NavigationMenuLink } from '~/components/shadcn/navigation-menu'
import { cn } from '~/lib/utils'

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  icon?: ReactElement | undefined
}

export const NavigationItem = forwardRef<React.ElementRef<'a'>, Props>(
  ({ className, title, children, icon, href, ...props }, ref) => {
    return (
      <NavigationMenuLink asChild>
        <Link
          href={href || ''}
          ref={ref}
          className={cn(
            'text-white block select-none rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
            className,
          )}
          {...props}
        >
          {icon ? <div className='text-sm font-medium leading-none float-left mr-2'>{icon}</div> : <></>}
          <div className='text-sm font-medium leading-none'>{title}</div>
          <p className='line-clamp-2 text-sm leading-snug text-muted-foreground'>{children}</p>
        </Link>
      </NavigationMenuLink>
    )
  },
)
NavigationItem.displayName = 'NavigationItem'
