import type {
  AddCountryListRequestBody,
  CountryListRequestParams,
  CountryListsResponse,
  UpdateCountryListRequestBody,
} from '@sneakersnstuff/sns-api-client/admin'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'
import type { CountryPickerFormSchema } from '~/components/countries/countryPicker'
import { useAdminApi } from '~/services/adminApi'

// todo: should be in typings
export const CountryPickerSchema = z.object({
  id: z.number(),
  instance: z.string(),
  name: z.string(),
  countries: z.array(z.string()).nullable(),
})
export type CountryPicker = z.infer<typeof CountryPickerSchema>
const countryArray = z.array(CountryPickerSchema)

export interface MappedCountryLists {
  EU: z.infer<typeof countryArray>
  US: z.infer<typeof countryArray>
  UK: z.infer<typeof countryArray>
}

const mapApiResponse = (countriesList: CountryListsResponse) => {
  const countriesListMapped: MappedCountryLists = {
    EU: [],
    US: [],
    UK: [],
  }

  for (const item of countriesList.countryLists) {
    const country: z.infer<typeof CountryPickerFormSchema> = {
      id: item.id,
      instance: item.instance,
      countries: [],
      name: item.name,
    }
    country.countries = item.countries.map((item) => {
      return item.country
    })

    switch (country.instance) {
      case 'EU':
        countriesListMapped.EU.push(country)
        break
      case 'US':
        countriesListMapped.US.push(country)
        break
      case 'UK':
        countriesListMapped.UK.push(country)
        break

      default:
        break
    }
  }

  return countriesListMapped
}

export const useGetCountryListsQuery = () => {
  const { countryLists } = useAdminApi()

  return useQuery({
    queryKey: ['countries'],
    queryFn: async (): Promise<MappedCountryLists> => {
      const countriesList = await countryLists.getCountryLists()
      const countriesListMapped = mapApiResponse(countriesList)
      return countriesListMapped
    },
  })
}

export const useAddCountryListMutation = () => {
  const { countryLists } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: AddCountryListRequestBody) => {
      return countryLists.addCountryList(body)
    },
    onSuccess: (data) => {
      const countriesListMapped = mapApiResponse(data)
      queryClient.setQueryData(['countries'], countriesListMapped)
    },
  })
}

export const useUpdateCountryListMutation = (params: CountryListRequestParams) => {
  const { countryListId } = params
  const { countryLists } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: UpdateCountryListRequestBody) => {
      return countryLists.updateCountryList({ countryListId }, body)
    },
    onSuccess: (data) => {
      const countriesListMapped = mapApiResponse(data)
      queryClient.setQueryData(['countries'], countriesListMapped)
    },
    onError: (error) => {
      console.log('onError', error)
    },
  })
}

export const useDeleteCountryListMutation = () => {
  const { countryLists } = useAdminApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (countryListId: CountryListRequestParams) => {
      return countryLists.deleteCountryList(countryListId)
    },
    onSuccess: (data) => {
      const countriesListMapped = mapApiResponse(data)
      queryClient.setQueryData(['countries'], countriesListMapped)
    },
  })
}

let selectedCountries: z.infer<typeof countryArray> = []
export const useGetSelectedCountriesQuery = () => {
  return useQuery({
    queryKey: ['countries', 'selected'],
    queryFn: () => {
      return selectedCountries
    },
  })
}

export const useSetSelectedCountriesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (countries: z.infer<typeof countryArray>) => {
      return new Promise((resolve) => {
        selectedCountries = countries
        return resolve(selectedCountries)
      })
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['countries', 'selected'], data)
    },
  })
}
