'use client'

import { type AdminApiClient, createAdminClient } from '@sneakersnstuff/sns-api-client/admin'
import { BackendErrors } from '@sneakersnstuff/sns-api-client/app/types/errors'
import { signOut } from 'next-auth/react'

import { type ReactNode, useEffect, useState } from 'react'
import { Button } from '~/components/shadcn/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '~/components/shadcn/collapsible'
import { useToast } from '~/components/shadcn/use-toast'
import { useEnvironment } from '~/hooks'

import { createContext } from '~/lib/createContext'

export const [Provider, useAdminApi] = createContext<AdminApiClient>({
  errorMessage: 'Using useAdminApi before ApiProvider.',
  name: 'SNS_ADMIN_API',
})

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const [api, setApi] = useState<AdminApiClient | null>(null)
  const { selectedEnvironment } = useEnvironment()
  const { toast } = useToast()

  useEffect(() => {
    let baseUrl = window.location.origin

    if (selectedEnvironment === 'dev') {
      baseUrl = `${baseUrl}/api/dev`
    }

    const buildApi = () => {
      const client = createAdminClient({
        baseUrl,
        // biome-ignore lint/suspicious/noExplicitAny: typing is wrong, need to fix it
        errorHandler: (errorMessage: any) => {
          if (errorMessage.message === BackendErrors.authInvalidToken) {
            console.log('logging you out')
            return signOut()
          }

          // User reloads the page
          if (errorMessage.error?.message === 'Request aborted') {
            return false
          }

          const { name, message, stack } = errorMessage.error

          toast({
            duration: 15000,
            variant: 'destructive',
            title: name,
            description: (
              <div>
                <p>{message}</p>
                <Collapsible>
                  <CollapsibleTrigger>
                    <Button className='mt-2'>Show Stack</Button>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <pre>{stack}</pre>
                  </CollapsibleContent>
                </Collapsible>
              </div>
            ),
          })
          return false
        },
      })

      setApi(client)
    }

    buildApi()
  }, [toast, selectedEnvironment])

  if (!api) {
    return null
  }

  return <Provider value={api}> {children} </Provider>
}
