import type {
  DeleteGlobalMessagePathParams,
  PostGlobalMessageRequestBody,
  PutGlobalMessagePathParams,
  PutGlobalMessageRequestBody,
} from '@sneakersnstuff/sns-api-client/admin'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useAdminApi } from '~/services/adminApi'

export const useGetGlobalMessagesQuery = () => {
  const { globalMessage } = useAdminApi()
  return useQuery({
    queryKey: ['globalMessages'],
    queryFn: async () => {
      const response = await globalMessage.get()
      return response
    },
  })
}

export const usePostGlobalMessageMutation = () => {
  const { globalMessage } = useAdminApi()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ data }: { data: PostGlobalMessageRequestBody }) => {
      const response = await globalMessage.create(data)
      return response
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: ['globalMessages'] })
      return response
    },
  })
}

export const usePutGlobalMessageMutation = () => {
  const { globalMessage } = useAdminApi()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      pathParams,
      data,
    }: {
      pathParams: PutGlobalMessagePathParams
      data: PutGlobalMessageRequestBody
    }) => {
      const response = await globalMessage.update(pathParams, data)
      return response
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: ['globalMessages'] })
      return response
    },
  })
}

export const useDeleteGlobalMessageMutation = () => {
  const { globalMessage } = useAdminApi()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ pathParams }: { pathParams: DeleteGlobalMessagePathParams }) => {
      const response = await globalMessage.delete(pathParams)
      return response
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: ['globalMessages'] })
      return response
    },
  })
}
