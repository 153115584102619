import { useTheme } from 'next-themes'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/shadcn/dropdown-menu'

import { Button } from '~/components/shadcn/button'

import { Check } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'
import { useEffect } from 'react'
import useWebSocket from 'react-use-websocket'
import { Avatar, AvatarFallback, AvatarImage } from '~/components/shadcn/avatar'
import { env } from '~/env'

export default function UserNavigation(): React.JSX.Element {
  const { data: session, update } = useSession()
  const { setTheme, theme } = useTheme()
  const { readyState } = useWebSocket(env.NEXT_PUBLIC_ADMIN_WEBSOCKET_API_URL)

  // Polling the session every 10min
  useEffect(() => {
    console.log('Starting session poller')
    const tenMinutes = 1000 * 60 * 10

    const interval = setInterval(() => {
      console.log('Updating session')
      update()
    }, tenMinutes)

    return () => clearInterval(interval)
  }, [update])

  if (!session) {
    return <>Login</>
  }

  const name = session.user?.name
  const splitName = name?.split(' ')
  const initials =
    splitName && splitName.length > 2 ? `${splitName.shift()?.substring(0, 1)} ${splitName.pop()?.substring(0, 1)}` : ''

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='ghost' className='relative h-10 w-10 rounded-full'>
          <Avatar className='h-10 w-10'>
            <AvatarImage src={session.user?.image || ''} alt={`Profile photo of ${session.user?.name}`} />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
          {readyState ? <p className='text-green-600 absolute bottom-0 right-0 h-4'>&#11044;</p> : <></>}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-56' align='end' forceMount>
        <DropdownMenuLabel className='font-normal'>
          <div className='flex flex-col space-y-1'>
            <p className='text-sm font-medium leading-none'>{session.user?.name}</p>
            <p className='text-xs leading-none text-muted-foreground'>{session.user?.email}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              setTheme('dark')
            }}
          >
            {theme === 'dark' ? <Check className='mr-1 mt-1 h-4' /> : <></>} Dark Mode
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setTheme('light')
            }}
          >
            {theme === 'light' ? <Check className='mr-1 mt-1 h-4' /> : <></>} Light Mode
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setTheme('system')
            }}
          >
            {theme === 'system' ? <Check className='mr-1 mt-1 h-4' /> : <></>} System Mode
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            void signOut()
          }}
        >
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
