import { useEffect, useRef, useState } from 'react'

import type { DrawHistory, WebsocketDrawLogData } from '@sneakersnstuff/sns-api-client/admin'
import { last } from 'lodash'
import { Check, ChevronDown, X } from 'lucide-react'
import Link from 'next/link'
import InstanceFlag from '~/components/icons/instanceFlag'
import { Spinner } from '~/components/loading/spinner'
import { Button } from '~/components/shadcn/button'
import { Card, CardContent } from '~/components/shadcn/card'
import { Progress } from '~/components/shadcn/progress'
import { useGetDrawSettingsQuery, useGetDrawStatusQuery, useRaffleQuery, useStopDrawMutation } from '~/hooks'
import { useGetCountryListsQuery } from '~/hooks/countryHooks'
import { useWebsocket } from '~/services/websocketProvider'
import RaffleImage from '../ui/raffleImage'

interface RaffleDrawProgressProps {
  drawHistory: DrawHistory
  drawHistoryOpenId: number
  setOpen: (open: number) => void
}

export const RaffleDrawProgress = ({
  drawHistory,
  drawHistoryOpenId,
  setOpen,
}: RaffleDrawProgressProps): React.JSX.Element => {
  const { messages } = useWebsocket()

  const { sku, instance } = drawHistory
  const isOpen = drawHistoryOpenId === drawHistory.id

  const [lastDrawLog, setLastDrawLog] = useState<string>('')

  const [winners, setWinners] = useState(0)
  const [stock, setStock] = useState(0)
  const [progress, setProgress] = useState(0)

  const [animationState, setAnimationState] = useState('hidden')
  const [lastOpenState, setLastOpenState] = useState(isOpen)

  const { data: drawSettings } = useGetDrawSettingsQuery(sku, instance)
  const { data: countryList } = useGetCountryListsQuery()
  const { data: raffle } = useRaffleQuery(sku, instance)
  const { isPending: stopDrawLoading, mutate: stopDrawMutate } = useStopDrawMutation()
  const { data: drawStatus } = useGetDrawStatusQuery(sku, instance)

  useEffect(() => {
    let newMessages: WebsocketDrawLogData[] = []
    for (const message of messages) {
      if (message.channel === 'draw') {
        if (message.sku === sku && message.instance === instance) {
          newMessages.push(message)
        }
      }
    }
    newMessages = newMessages.sort((a, b) => a.timestamp - b.timestamp)

    if (newMessages) {
      const lastMessage = newMessages?.pop()
      if (lastMessage) {
        setLastDrawLog(lastMessage.msg)
      }
    }
  }, [messages, instance, sku])

  useEffect(() => {
    if (isOpen !== lastOpenState) {
      if (isOpen) {
        setAnimationState('duration-500 delay-250 animate-in slide-in-from-bottom')
      } else {
        setAnimationState('duration-500 animate-out slide-out-to-bottom translate-y-full')
      }
      setLastOpenState(isOpen)
    }
  }, [isOpen, lastOpenState])

  useEffect(() => {
    const winners = drawHistory.progress.stockLevels.reduce((accumulator, stockLevel) => {
      return accumulator + stockLevel.winners
    }, 0)
    const stock = drawHistory.progress.stockLevels.reduce((accumulator, stockLevel) => {
      return accumulator + stockLevel.stock
    }, 0)
    const progress = Math.round((winners / stock) * 100) || 0
    setWinners(winners)
    setStock(stock)
    setProgress(progress)
  }, [drawHistory])

  // if (!drawSettings || !countryList || !raffle || !drawStatus) {
  //   return <></>
  // }

  const locale = navigator.languages?.length ? navigator.languages[0] : navigator.language
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localShortTime = new Intl.DateTimeFormat(locale, {
    timeStyle: 'long',
    timeZone: localTimeZone,
  }).format(drawHistory.drawDate)

  const isCompleted = drawHistory.progress.completed

  const gotData = drawSettings && countryList && raffle && drawStatus

  return (
    <div className={`${animationState} w-full fixed bottom-0 left-0 `}>
      <Card className={'m-4 h-[120px]'}>
        <CardContent className='p-0'>
          {gotData ? (
            <>
              <RaffleImage className=' float-left me-4 mx-auto' width={120} height={120} raffle={raffle} />
              <div className='flex flex-row'>
                <div className='basis-1/2 mt-2'>
                  <h1 className='text-xl w-full'>
                    Drawing:{' '}
                    <Link className='underline' href={`/raffle/${raffle.instance}/${raffle.product.sku}/draw`}>
                      {raffle.product.name}
                    </Link>
                  </h1>

                  <div className=''>
                    <InstanceFlag className='float-left mr-1 mt-1' instance={instance} height={15} />
                    <span>{instance}</span>
                    <p className='text-gray-700 inline-block ml-3 text-sm'>Started: {localShortTime}</p>
                  </div>

                  <pre>{lastDrawLog}</pre>
                </div>

                <div className='w-full'>
                  <div className='basis-1/2 mt-2 mr-3 h-[50px]'>
                    {drawHistory.active && !isCompleted ? (
                      <ChevronDown className='float-right ml-4 mb-5 cursor-pointer' onClick={() => setOpen(-1)} />
                    ) : (
                      <X className='float-right ml-4 mb-5 cursor-pointer' onClick={() => setOpen(-1)} />
                    )}
                    <div className='columns-3'>
                      <p className='text-xl'>
                        Signups Left: {drawStatus.total.perCountryList?.[drawSettings.countryListId]?.signups}
                      </p>
                      <p className='text-xl text-center'>Stock Left: {stock}</p>
                      <p className='text-xl text-end'>Winners: {winners}</p>
                    </div>
                  </div>

                  <div className='flex flex-row mt-2 w-full pr-2'>
                    {drawHistory.active && !isCompleted ? (
                      <>
                        <div className='basis-3/4 mr-2 relative'>
                          <Progress className='w-full h-10 absolute' value={progress} />
                          <p className='text-center align-middle absolute w-full h-full pt-2'>Completed: {progress}%</p>
                        </div>
                        <div className='basis-1/4'>
                          <Button
                            className='w-full'
                            loading={stopDrawLoading}
                            disabled={stopDrawLoading}
                            onClick={() => {
                              stopDrawMutate({ sku, instance })
                            }}
                          >
                            Stop draw
                          </Button>
                        </div>
                      </>
                    ) : (
                      <p className='text-end w-full h-full pt-4 mr-2'>
                        <Check className='inline-block mr-1 mb-1' />
                        Draw Completed
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='p-0 flex justify-center items-center content-around w-full h-[120px]'>
              <Spinner visible size={25} />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
