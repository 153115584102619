import type { BCInstance } from '@sneakersnstuff/sns-api-client/app/types'

import { EU, GB, US } from 'country-flag-icons/react/3x2'

interface InstanceFlagProps {
  instance: BCInstance
  height?: number
  className?: string
}

const InstanceFlag = (props: InstanceFlagProps) => {
  const height = props.height ?? 20
  const className = props.className ?? ''
  switch (props.instance) {
    case 'EU':
      return (
        <span className={className}>
          <EU title='EU Flag' height={height} />
        </span>
      )
    case 'US':
      return (
        <span className={className}>
          <US title='US Flag' height={height} />
        </span>
      )
    case 'UK':
      return (
        <span className={className}>
          <GB title='GB Flag' height={height} />
        </span>
      )
  }
}

export default InstanceFlag
