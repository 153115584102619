import * as React from 'react'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '~/components/shadcn/select'
import { useEnvironment } from '~/hooks'
import type { WebAdminEnvironment } from '~/lib/types'

export const EnvironmentPicker = () => {
  const { selectedEnvironment, setSelectedEnvironment } = useEnvironment()

  const onValueChange = (value: string) => {
    setSelectedEnvironment(value as WebAdminEnvironment)
  }

  return (
    <Select onValueChange={onValueChange} value={selectedEnvironment}>
      <SelectTrigger className='w-[120px]'>
        <SelectValue placeholder='Local' />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Selected Endpoint:</SelectLabel>
          <SelectItem value='local'>API: Local</SelectItem>
          <SelectItem value='dev'>API: Dev</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
