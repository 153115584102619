import { useEffect, useState } from 'react'

import type { WebAdminEnvironment } from '~/lib/types'
import { getEnvironment, setEnvironment } from '~/services/localstorage'

export const useEnvironment = () => {
  const initialData: WebAdminEnvironment = 'local'
  const current = getEnvironment()

  const [selectedEnvironment, setSelectedEnvironment] = useState<WebAdminEnvironment>(current ? current : initialData)

  useEffect(() => {
    if (current !== selectedEnvironment) {
      setEnvironment(selectedEnvironment)
      // todo: need to turn this into a context or something so the API client can listen for changes
      // lets just reload for now
      window.location.reload()
    }
  }, [current, selectedEnvironment])

  return { selectedEnvironment, setSelectedEnvironment }
}
